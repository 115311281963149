var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-submission-form" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v("\n    Submit a Case Study\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "20px" } }),
      _vm._v(" "),
      _c("p", { staticClass: "max-width" }, [
        _vm._v(
          "\n    The Genentech brand is a living thing. Help keep our case studies relevant\n    and inclusive with representation from all areas of Genentech. Fill out\n    the form below and send us your work.\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "FormRoot",
        {
          staticClass: "max-width",
          attrs: {
            "form-id": "showcase-submission",
            "enable-submit": !_vm.submitting,
            "submit-button-text": `${
              _vm.submitting ? "Submitting..." : "Submit a Case Study"
            }`,
          },
        },
        [
          _c("h2", { staticClass: "form-category mb-4" }, [
            _vm._v("\n      Contact Information\n    "),
          ]),
          _vm._v(" "),
          _c("TextInput", {
            ref: "firstName",
            attrs: {
              model: _vm.firstName,
              label: "First Name",
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.firstName = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextInput", {
            ref: "lastName",
            attrs: {
              model: _vm.lastName,
              label: "Last Name",
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.lastName = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextInput", {
            ref: "email",
            attrs: {
              model: _vm.email,
              label: "Email Address",
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.email = $event
              },
            },
          }),
          _vm._v(" "),
          _c("MultiselectInput", {
            ref: "projectType",
            attrs: {
              list: _vm.projectTypeSelectList,
              model: _vm.projectType,
              placeholder: "Select",
              required: true,
              label: "Project Type",
            },
            on: {
              "update:model": function ($event) {
                _vm.projectType = $event
              },
              select: _vm.onSelectProjectType,
            },
          }),
          _vm._v(" "),
          _c("MultiselectInput", {
            ref: "audiences",
            attrs: {
              list: _vm.audiencesSelectList,
              model: _vm.audiences,
              placeholder: "Select",
              required: true,
              label: "Audience",
            },
            on: {
              "update:model": function ($event) {
                _vm.audiences = $event
              },
              select: _vm.onSelectAudiences,
            },
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "form-category mb-4" }, [
            _vm._v("\n      Project Information\n    "),
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "input_title" }, [
            _vm._v("\n      Project Title\n    "),
          ]),
          _vm._v(" "),
          _c("TextInput", {
            ref: "title",
            staticClass: "no-label mb-0",
            attrs: {
              model: _vm.title,
              required: "true",
              validate: "true",
              placeholder: "e.g. Internal Neuroscience Campaign",
              maxlength: _vm.maxTitleChars,
            },
            on: {
              "update:model": function ($event) {
                _vm.title = $event
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _c("small", [
              _vm._v(_vm._s(_vm.titleCharsLeft) + " characters left"),
            ]),
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "input_title" }, [
            _vm._v("\n      About the Project\n    "),
          ]),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "description",
            staticClass: "mb-0",
            attrs: {
              placeholder:
                "Tell us more about the goals of the project. Perhaps tell us about the insights and/or inspiration for the project",
              model: _vm.description,
              required: "true",
              validate: "true",
              maxlength: _vm.maxDescriptionChars,
              rows: 10,
            },
            on: {
              "update:model": function ($event) {
                _vm.description = $event
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _c("small", [
              _vm._v(_vm._s(_vm.descriptionCharsLeft) + " characters left"),
            ]),
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "form-category mb-4" }, [
            _vm._v("\n      Related Links or Attachments\n    "),
          ]),
          _vm._v(" "),
          _c("ShowcaseFileUpload", {
            ref: "fileUpload",
            attrs: {
              "upload-button-text": "Add File(s)",
              "upload-button-border-styles": "1px solid #d8d8d8",
              "upload-item-background-color": "#f9f9f9",
              "upload-button-width": "uploadButtonWidth",
              "upload-button-height": "uploadButtonHeight",
              "upload-button-padding": "20px 40px",
              "upload-files-demo": "uploadFilesDemo",
              "upload-button-color": "#fff",
            },
            on: { "send-hash-collection": _vm.onHashCollectionReady },
          }),
          _vm._v(" "),
          _c("ShowcaseLinks", {
            ref: "showcaseLinks",
            attrs: {
              links: _vm.links,
              placeholder_text: "e.g. Policies",
              placeholder_url: "e.g. http://www.site.com/link",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }