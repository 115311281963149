
import Base from '@core/plugins/Showcase/core/components/partials/grid-item'

export default {
  name: 'ClientShowcaseGridItem',
  extends: Base,
  computed: {
    subtitle () {
      if (this.projectTypeId) {
        let filter = this.showcaseSubmission.filters.find(item => item.parent_id === this.projectTypeId)

        return filter?.label ?? ''
      }

      return ''
    },
    projectTypeId () {
      return this.showcase?.filters.find(item => item.label === 'Project Type').id ?? null
    }
  }
}
