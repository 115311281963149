var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", { staticClass: "title" }, [_vm._v("\n    Case Studies\n  ")]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticStyle: {
            "font-size": "24px",
            "line-height": "36px",
            color: "#25282a",
            "max-width": "936px",
            "margin-bottom": "76px",
          },
        },
        [
          _vm._v(
            "\n    Here, we celebrate your good work. See just some of the many successful executions of the Genentech brand. \n    These projects were created in a collaboration with the Corporate Brand Marketing team and teams like yours.\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "btn primary link-button",
          attrs: {
            to: _vm.$generateRoute({
              name: "showcase.submit",
              params: {
                showcaseId: _vm.showcaseId,
              },
            }),
          },
        },
        [_vm._v("\n    Submit your project\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }