module.exports = {
  ic_apps: {
    viewbox: '0 0 24 24',
    content: '<path fill="currentColor" fill-rule="evenodd" d="M21 13.25a.75.75 0 01.75.75v7a.75.75 0 01-.75.75h-7a.75.75 0 01-.75-.75v-7a.75.75 0 01.75-.75zm-11 0a.75.75 0 01.75.75v7a.75.75 0 01-.75.75H3a.75.75 0 01-.75-.75v-7a.75.75 0 01.75-.75zm10.25 1.5h-5.5v5.5h5.5v-5.5zm-11 0h-5.5v5.5h5.5v-5.5zM10 2.25a.75.75 0 01.75.75v7a.75.75 0 01-.75.75H3a.75.75 0 01-.75-.75V3A.75.75 0 013 2.25zm11 0a.75.75 0 01.75.75v7a.75.75 0 01-.75.75h-7a.75.75 0 01-.75-.75V3a.75.75 0 01.75-.75zM9.25 3.75h-5.5v5.5h5.5v-5.5zm11 0h-5.5v5.5h5.5v-5.5z"/>'
  },
  ic_bookmark: {
    viewbox: '0 0 24 24',
    content: '<path d="M19 18l2 1V3c0-1.1-.9-2-2-2H8.99C7.89 1 7 1.9 7 3h10c1.1 0 2 .9 2 2v13zM15 5H5c-1.1 0-2 .9-2 2v16l7-3 7 3V7c0-1.1-.9-2-2-2z"/>'
  },
  ic_bookmark_border: {
    viewbox: '0 0 24 24',
    content: '<path d="M15 7v12.97l-4.21-1.81-.79-.34-.79.34L5 19.97V7h10m4-6H8.99C7.89 1 7 1.9 7 3h10c1.1 0 2 .9 2 2v13l2 1V3c0-1.1-.9-2-2-2zm-4 4H5c-1.1 0-2 .9-2 2v16l7-3 7 3V7c0-1.1-.9-2-2-2z"/>'
  },
  ic_play_circle_filled: {
    viewbox: '0 0 120 120',
    content: '<path d="M66.4299717,45.7166195 L83.3652102,73.942017 C84.5018028,75.836338 83.8875426,78.2933791 81.9932215,79.4299717 C81.3715599,79.8029687 80.6602143,80 79.9352385,80 L46.0647615,80 C43.8556225,80 42.0647615,78.209139 42.0647615,76 C42.0647615,75.2750242 42.2617928,74.5636786 42.6347898,73.942017 L59.5700283,45.7166195 C60.7066209,43.8222984 63.163662,43.2080382 65.057983,44.3446308 C65.6209814,44.6824299 66.0921727,45.1536211 66.4299717,45.7166195 Z" transform="rotate(90 63 60)"/>'
  },
  ic_tune: {
    viewbox: '0 0 24 24',
    content: '<defs><path id="a" d="M22 5.924h-3.643C18.017 4.222 16.555 3 14.857 3c-1.697 0-3.16 1.222-3.5 2.924H2v1.461h9.358c.339 1.702 1.802 2.924 3.5 2.924 1.697 0 3.16-1.222 3.499-2.924H22V5.924zm-7.143 2.923c-1.183 0-2.143-.982-2.143-2.193 0-1.21.96-2.192 2.143-2.192 1.184 0 2.143.982 2.143 2.192-.001 1.21-.96 2.191-2.143 2.193zM22 16.614h-9.358c-.339-1.7-1.802-2.923-3.5-2.923-1.697 0-3.16 1.222-3.499 2.924H2v1.461h3.643C5.983 19.778 7.445 21 9.143 21c1.697 0 3.16-1.222 3.5-2.924H22v-1.462zM9.143 19.538C7.959 19.538 7 18.556 7 17.346c0-1.211.96-2.193 2.143-2.193 1.183 0 2.143.982 2.143 2.193-.002 1.21-.96 2.19-2.143 2.192z"/></defs><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><use fill="currentColor" xlink:href="#a"/></g>'
  },
  ic_view_list: {
    viewbox: '0 0 24 24',
    content: '<path fill="currentColor" fill-rule="evenodd" d="M21 18.25a.75.75 0 01.102 1.493L21 19.75H8a.75.75 0 01-.102-1.493L8 18.25h13zm-17.25 0v1.5h-1.5v-1.5h1.5zm17.25-7a.75.75 0 01.102 1.493L21 12.75H8a.75.75 0 01-.102-1.493L8 11.25h13zm-17.25 0v1.5h-1.5v-1.5h1.5zm17.25-7a.75.75 0 01.102 1.493L21 5.75H8a.75.75 0 01-.102-1.493L8 4.25h13zm-17.25 0v1.5h-1.5v-1.5h1.5z"/>'
  }
}
