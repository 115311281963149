var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "showcase-nav-buttons-container" }, [
    _vm.displayPrev
      ? _c("a", {
          staticClass: "link-button prev",
          staticStyle: { "font-weight": "900", "margin-right": "10px" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.prev.apply(null, arguments)
            },
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.displayNext
      ? _c("a", {
          staticClass: "link-button next",
          staticStyle: { "font-weight": "900" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.next.apply(null, arguments)
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }