var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("figure", { staticClass: "video-figure" }, [
    _c(
      "p",
      {
        staticClass: "offscreen",
        attrs: { id: `video-description-${_vm.moduleData.id}` },
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.player.description || _vm.moduleData.desc) +
            "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "video-wrapper" },
      [
        _c("video", {
          ref: "video",
          staticClass: "video-player",
          attrs: {
            src: _vm.url,
            poster: _vm.poster,
            controls: _vm.controlsSwitch,
            autoplay: _vm.player.autoplay,
            loop: _vm.player.loop,
            controlslist: "nodownload",
            disablePictureInPicture: "",
            title: _vm.player.description || _vm.moduleData.desc,
            "aria-describedby": `video-description-${_vm.moduleData.id}`,
            tabindex: "0",
          },
          domProps: { muted: _vm.player.muted },
          on: {
            play: function ($event) {
              _vm.showPlayIcon = false
            },
            ended: _vm.onEnd,
            pause: _vm.onPause,
            seeking: _vm.onSeeking,
            seeked: _vm.onFinishSeeking,
            loadeddata: _vm.onLoad,
            keydown: function ($event) {
              !_vm._isAdminArea ? _vm.$toggleVideoPlay : null
            },
          },
        }),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade" } }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPlayIcon && _vm.player.controls,
                  expression: "showPlayIcon && player.controls",
                },
              ],
              ref: "playBtn",
              staticClass: "play",
              attrs: {
                type: "button",
                role: "presentation",
                "data-state": "play",
                "aria-label": "Play",
                tabindex: "-1",
              },
              on: { click: _vm.onPlay },
            },
            [
              _c("icon", {
                staticClass: "play-icon",
                attrs: { id: "ic_play_circle_filled" },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _vm.player.caption
      ? _c("figcaption", [
          _vm._v("\n    " + _vm._s(_vm.player.caption) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }