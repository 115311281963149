var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "submission-layout" },
    [
      _c(
        "div",
        [
          _c("back-button", {
            attrs: {
              "showcase-id": _vm.showcaseId,
              "button-text": "Back to " + (_vm.showcase && _vm.showcase.name),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isPublished
        ? _c("ShowcaseNavigationButtons", { staticClass: "nav-top" })
        : _vm._e(),
      _vm._v(" "),
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.submission && _vm.submission.title) + "\n  "
        ),
      ]),
      _vm._v(" "),
      _vm.sliderData
        ? _c(
            "div",
            [
              _c("ShowcaseSlider", {
                staticClass: "mb-lg",
                attrs: {
                  id: "showcase_submission_slider",
                  slides: _vm.sliderData,
                  "slideshow-delay": "7000",
                  "show-controls": true,
                  "show-indicators": true,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showcase
        ? _c("div", [
            _c("h1", {}, [_vm._v("\n      About the Project\n    ")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.submission && _vm.submission.description) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column column_33" }, [
                _c("div", { staticClass: "col_title" }, [
                  _vm._v("\n          PROJECT TYPES\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "filters" },
                  _vm._l(_vm.projectTypes, function (type) {
                    return _c("li", { key: type }, [
                      _vm._v("\n            " + _vm._s(type) + "\n          "),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column column_33" }, [
                _c("div", { staticClass: "col_title" }, [
                  _vm._v("\n          PROJECT LEAD\n        "),
                ]),
                _vm._v("\n        " + _vm._s(_vm.author) + " "),
                _c("br"),
                _vm._v("\n        " + _vm._s(_vm.email) + "\n      "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column column_33" }, [
                _c("div", { staticClass: "col_title" }, [
                  _vm._v("\n          AUDIENCE\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "filters" },
                  _vm._l(_vm.audience, function (item) {
                    return _c("li", { key: item }, [
                      _vm._v("\n            " + _vm._s(item) + "\n          "),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _vm.relatedLinks.length > 0
                ? _c(
                    "div",
                    { staticClass: "column column_33" },
                    [
                      _c("div", { staticClass: "col_title" }, [
                        _vm._v("\n          RELATED LINKS\n        "),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.relatedLinks, function (link, index) {
                        return _c("div", { key: index, staticClass: "links" }, [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: link.url, target: "_blank" },
                            },
                            [_vm._v(_vm._s(link.text))]
                          ),
                        ])
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }