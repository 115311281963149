var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "client_showcase_featured" },
    [
      _c("h1", [_vm._v("FEATURED WORK")]),
      _vm._v(" "),
      _c("ShowcaseSlider", {
        attrs: {
          id: "showcase_slider",
          "slideshow-delay": "7000",
          "show-controls": true,
          "show-indicators": true,
          slides: _vm.slides,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }