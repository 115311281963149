// clientName MUST be specified, as this is used to build the API URL
// Regex for one lower, one upper, one special, one number, at least 8 characters =
//     "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})";

const ClientConfig = {
  clientName: 'genentech',
  environment: {
    development: {
      login: 'login',
      ssoLogin: 'http://api.beamapi.localdev:81/v1/authentication/sso?sp=default-sp&redirect_url=http://test-client.localdev:8080',
      logout: 'login'
    },
    production: {
      login: 'login',
      ssoLogin: ['https://authenticator.monigle.net/v1/authentication/sso?sp=prod-sp-genentech&redirect_url=https://brandcenter.gene.com'],
      logout: 'login'
    },
    qa: {
      login: 'login',
      ssoLogin: 'https://authenticator.monigle3.net/v1/authentication/sso?sp=qa-sp-genentech&redirect_url=https://genentech.beam3.monigle3.net',
      logout: 'login'
    },
    staging: {
      login: 'login',
      ssoLogin: ['https://authenticator.monigle2.net/v1/authentication/sso?sp=staging-sp-onelogin&redirect_url=https://genentech.beam3.monigle2.net'],
      logout: 'login'
    },
    demo: {
      login: 'login',
      ssoLogin: 'https://beam3api-demo.monigle3.net/v1/authentication/sso?sp=demo-sp&redirect_url=https://unc-health-demo.beam3.monigle3.net',
      logout: 'login'
    }
  },
  settings: {
    // passwordRegex: '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
    passwordRequirements: {
      length: 8,
      lowercase: 1,
      number: 1,
      symbol: 1,
      uppercase: 1
    }
  },
  theme: {
    themeStyles: {
      desktopAssetsTableGridCols: 3,
      hasAltHomepageDesktopLogo: false
    }
  },
  include_modules: ['ShowcaseCarousel'],
  exclude_modules: [],
  request_headers: require('@core/js/config/request-headers')
}
export default ClientConfig
