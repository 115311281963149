var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormRoot",
    {
      attrs: {
        "form-id": "showcase-submission",
        "enable-submit": true,
        "show-save-button": false,
      },
    },
    [
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    Contact Information:\n  "),
      ]),
      _vm._v(" "),
      _c("TextInput", {
        ref: "firstName",
        attrs: {
          model: _vm.firstName,
          label: "First Name",
          required: "true",
          validate: "true",
        },
        on: {
          "update:model": function ($event) {
            _vm.firstName = $event
          },
        },
      }),
      _vm._v(" "),
      _c("TextInput", {
        ref: "lastName",
        attrs: {
          model: _vm.lastName,
          label: "Last Name",
          required: "true",
          validate: "true",
        },
        on: {
          "update:model": function ($event) {
            _vm.lastName = $event
          },
        },
      }),
      _vm._v(" "),
      _c("TextInput", {
        ref: "email",
        attrs: {
          model: _vm.email,
          label: "Email Address",
          required: "true",
          validate: "true",
        },
        on: {
          "update:model": function ($event) {
            _vm.email = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "32px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    Project Type\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "responsive-3-columns" },
        [
          _c("CheckboxArrayInput", {
            ref: "type",
            attrs: {
              list: _vm.projectTypeSelectList,
              model: _vm.projectType,
              name: "type",
              required: true,
            },
            on: {
              "update:model": function ($event) {
                _vm.projectType = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "32px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    Audience\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "responsive-3-columns" },
        [
          _c("CheckboxArrayInput", {
            ref: "audiences",
            attrs: {
              list: _vm.audiencesSelectList,
              model: _vm.audiences,
              name: "audiences",
              required: true,
            },
            on: {
              "update:model": function ($event) {
                _vm.audiences = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "64px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category mb-4" }, [
        _vm._v("\n    Project Information\n  "),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "input_title" }, [
        _vm._v("\n    Project Title\n  "),
      ]),
      _vm._v(" "),
      _c("TextInput", {
        ref: "title",
        staticClass: "no-label mb-0",
        attrs: {
          model: _vm.title,
          required: "true",
          validate: "true",
          placeholder: "e.g. Internal Neuroscience Campaign",
          maxlength: _vm.maxTitleChars,
        },
        on: {
          "update:model": function ($event) {
            _vm.title = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-right mb-3" }, [
        _c("small", [_vm._v(_vm._s(_vm.titleCharsLeft) + " characters left")]),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "input_title" }, [
        _vm._v("\n    About the Project\n  "),
      ]),
      _vm._v(" "),
      _c("TextareaInput", {
        ref: "description",
        staticClass: "mb-0",
        attrs: {
          placeholder:
            "Tell us more about the goals of the project. Perhaps tell us about the insights and/or inspiration for the project",
          model: _vm.description,
          required: "true",
          validate: "true",
          maxlength: _vm.maxDescriptionChars,
          rows: 10,
        },
        on: {
          "update:model": function ($event) {
            _vm.description = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-right mb-3" }, [
        _c("small", [
          _vm._v(_vm._s(_vm.descriptionCharsLeft) + " characters left"),
        ]),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category mb-4" }, [
        _vm._v("\n    Related Links or Attachments:\n  "),
      ]),
      _vm._v(" "),
      _c("ShowcaseFileUpload", {
        ref: "files",
        attrs: {
          "upload-button-text": "Add File(s)",
          "initial-file-hash-collection": _vm.fileHashCollection,
        },
        on: { "send-hash-collection": _vm.onHashCollectionReady },
      }),
      _vm._v(" "),
      _c("ShowcaseLinks", {
        ref: "showcaseLinks",
        attrs: {
          links: _vm.links,
          placeholder_text: "e.g. Policies",
          placeholder_url: "e.g. http://www.site.com/link",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }