var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-carousel",
        {
          ref: "showcaseCarousel",
          attrs: {
            id: _vm.id,
            interval: _vm.interval,
            controls: _vm.controls,
            indicators: _vm.indicatorsBoolean,
          },
          on: {
            "sliding-start": _vm.onSlideStart,
            "sliding-end": _vm.onSlideEnd,
          },
          model: {
            value: _vm.slide,
            callback: function ($$v) {
              _vm.slide = $$v
            },
            expression: "slide",
          },
        },
        _vm._l(_vm.slides, function (slide, index) {
          return _c(
            "div",
            { key: index },
            [
              _c(
                "b-carousel-slide",
                [
                  _c("div", {
                    staticClass: "background-image",
                    style: `background: url(${slide.img})`,
                    on: { click: _vm.advanceImage },
                  }),
                  _vm._v(" "),
                  slide.mime_type && slide.mime_type.includes("video")
                    ? _c(
                        "button",
                        {
                          staticClass: "play-button",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.playVideo(slide.src)
                            },
                          },
                        },
                        [
                          _c("icon", {
                            attrs: { id: "ic_play_circle_filled" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("div", { staticClass: "hide-mobile" }),
                              _vm._v(" "),
                              slide.title
                                ? _c("h1", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(slide.title) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              slide.subtitle
                                ? _c("h3", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(slide.subtitle) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              typeof slide.likes === "number"
                                ? _c(
                                    "div",
                                    { staticClass: "favorites" },
                                    [
                                      _vm.isLiked(slide.submission_id)
                                        ? _c("icons", {
                                            attrs: {
                                              name: "showcase-favorite-fill",
                                            },
                                          })
                                        : _c("icons", {
                                            attrs: {
                                              name: "showcase-favorite",
                                            },
                                          }),
                                      _vm._v(" "),
                                      slide.likes > 0
                                        ? _c("p", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(slide.likes) +
                                                "\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              slide.link_text
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass: "carousel-link",
                                      attrs: {
                                        to: slide.link_url,
                                        target: slide.link_target,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.ctaButtonText) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }