
import Base from "@core/plugins/Showcase/core/components/client-showcase-grid.vue"
import GridItem from './grid-item.vue'
export default {
    name: 'ClientShowcaseGrid',
    components: {
      GridItem,
    },
    extends: Base
}
