var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "focus-trap",
    {
      model: {
        value: _vm.displayModal,
        callback: function ($$v) {
          _vm.displayModal = $$v
        },
        expression: "displayModal",
      },
    },
    [
      _c("section", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
        _c(
          "div",
          {
            class: [
              "modal__body",
              "video",
              {
                embed:
                  _vm.ctaContentType === "embeded" &&
                  _vm.videoSource === "embed_code",
              },
            ],
          },
          [
            _c("div", { staticClass: "modal__controls" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", title: "Close" },
                  on: { click: _vm.onClose },
                },
                [_c("icon", { attrs: { id: "ic_close" } })],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal__contents" }, [
              _c("div", { staticClass: "video_container" }, [
                _vm.ctaContentType === "uploaded"
                  ? _c(
                      "div",
                      { staticClass: "uploaded" },
                      [
                        _c("video-player", {
                          attrs: { "module-data": _vm.videoData },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticClass: "embedded",
                  domProps: { innerHTML: _vm._s(_vm.ctaContentVideoEmbedCode) },
                }),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }