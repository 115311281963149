var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.classes }, [
    _vm.authorName
      ? _c("div", [
          _c("p", { staticClass: "info-title" }, [
            _vm._v("\n      Authorrrr:\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "info-content" }, [
            _vm._v("\n      " + _vm._s(_vm.authorName) + "\n    "),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.officeName
      ? _c("div", [
          _c("p", { staticClass: "info-title" }, [
            _vm._v("\n      Office:\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "info-content" }, [
            _vm._v("\n      " + _vm._s(_vm.officeName) + "\n    "),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.departmentName
      ? _c("div", [
          _c("p", { staticClass: "info-title" }, [
            _vm._v("\n      Team:\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "info-content" }, [
            _vm._v("\n      " + _vm._s(_vm.departmentName) + "\n    "),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.scopeName
      ? _c("div", [
          _c("p", { staticClass: "info-title" }, [
            _vm._v("\n      Project Scope:\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "info-content" }, [
            _vm._v("\n      " + _vm._s(_vm.scopeName) + "\n    "),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.audienceName
      ? _c("div", [
          _c("p", { staticClass: "info-title" }, [
            _vm._v("\n      Audience:\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "info-content" }, [
            _vm._v("\n      " + _vm._s(_vm.audienceName) + "\n    "),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.type
      ? _c("div", [
          _c("p", { staticClass: "info-title" }, [
            _vm._v("\n      Project Type:\n    "),
          ]),
          _vm._v(" "),
          _c("p", {
            directives: [
              {
                name: "sanitize",
                rawName: "v-sanitize",
                value: _vm.type,
                expression: "type",
              },
            ],
            staticClass: "info-content",
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.industry
      ? _c("div", [
          _c("p", { staticClass: "info-title" }, [
            _vm._v("\n      Industry:\n    "),
          ]),
          _vm._v(" "),
          _c("p", {
            directives: [
              {
                name: "sanitize",
                rawName: "v-sanitize",
                value: _vm.industry,
                expression: "industry",
              },
            ],
            staticClass: "info-content",
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.capability
      ? _c("div", [
          _c("p", { staticClass: "info-title" }, [
            _vm._v("\n      Capability Area:\n    "),
          ]),
          _vm._v(" "),
          _c("p", {
            directives: [
              {
                name: "sanitize",
                rawName: "v-sanitize",
                value: _vm.capability,
                expression: "capability",
              },
            ],
            staticClass: "info-content",
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.linkText
      ? _c("div", [
          _c("p", { staticClass: "info-title" }, [
            _vm._v("\n      Related Links:\n    "),
          ]),
          _vm._v(" "),
          _c("p", {
            directives: [
              { name: "linkified", rawName: "v-linkified" },
              {
                name: "sanitize",
                rawName: "v-sanitize",
                value: _vm.linkText,
                expression: "linkText",
              },
            ],
            staticClass: "info-content",
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }